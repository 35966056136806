<template>
  <v-main class="container-prosker top-container">
    <div
      class="wide-banner text_contrast_to_background"
      :class="localeClass"
      :style="{
        backgroundImage: `url(${i18nImage('vector_home_main_banner.jpg')})`,
      }"
    >
      <v-row class="pl-3 pr-3">
      <v-col
        class="prosker-text-section text-center d-flex flex-column mt-8 p-0"
        col="12"
        md="6"
      >
        <h1
          class="text-h4 text-md-h2 mb-4 secondary--text font-weight-bold line-height mt-9 text-lg-left"
          v-html="$t('grow_business')"
        ></h1>
        <span class="text-h6 main_text_color--text text-lg-left">{{ $t('platform')  }}</span>
        <div class="mt-8 gap-2 d-flex flex-column flex-md-row">
          <general-button
            :action="goToSubscriptions"
            :loading="false"
            :message="$t('begin')"
            size="medium"
            cssClasses="flex-grow-xs-1"
          />
          <general-button
            :action="goToBuy"
            :loading="false"
            :message="$t('wantToHire')"
            size="medium"
            cssClasses="flex-grow-xs-1"
            outlined
          />
        </div>
        <div class="actions-sub-text d-flex gap-2 flex-column flex-md-row mt-8">
          <p class="text-center"> ✓ {{ $t('freeTrial30') }}</p>
          <p class="text-center"> ✓ {{ $t('buySafe') }}</p>
        </div>
      </v-col>
      <v-col
        class="hero-side-image text-center d-md-flex d-none"
        cols="6"
      >
        <div>
          <img :src="i18nImage('home_main_banner.png')" width="100%" >
        </div>
      </v-col>
      </v-row>
    </div>
    <div class="wide-banner text_contrast_to_background second-banner" :class="localeClass">
      <v-parallax
        dark
        :src="require('@/assets/images/page-img/home_main_banner2.jpg')"
        class="border-radius-medium"
      >
        <testimonials-card :description="$t('testimonialDesc')" :userName="$t('testimonialName')"/>
        <img :src="i18nImage('home_main_banner-user.png')" class="banner-user-menu" />
      </v-parallax>
    </div>
    <div
      class="wide-banner vector-bg text_contrast_to_background"
      :style="{
        backgroundImage: `url(${require('@/assets/images/page-img/half-vector.png')})`,
      }"
    >
    <v-row class="pt-16 pl-3 pr-3 pr-lg-0 pl-lg-0">
      <v-col  md="6" xs="12" sm="12" >
        <p class="secondary-prosk text-h4 text-md-h3  text-center text-md-left font-weight-bold d-block d-md-inline " v-html="$t('future_entrepreneur')"/>
        <div class="d-block d-md-inline text-center text-md-left">
        <img src="@/assets/images/page-img/avatarGroup.png" class="img-fluid ">
      </div>
      </v-col>
      <v-col  md="6" xs="12" sm="12" class="text-center position-relative">
        <div style="height: 500px;" >
          <div class="d-flex flex-column align-center align-md-start">
            <p class="text-left w-75 font-size-16 text-center text-md-left">
              <span class="main_text_color--text" v-html="$t('more_control')"/>
            </p>
            <general-button :action="goToSubscriptions" :loading="false" :message="$t('startNow')"  size="medium"  cssClasses="align-self-center align-self-md-start mt-2"/>
          </div>
        </div>
      </v-col>
    </v-row>
    </div>
    <v-row class="py-8 text-center background_contrast align-center pl-3 pr-3 pr-lg-0 pl-lg-0">
        <v-col cols="12" sm="12" md="6" >
            <img :src="i18nImage('home_main_banner3.png')" class="img-fluid">
        </v-col>
        <v-col cols="12" sm="12" md="5" offset-md="1" >
          <h1 class="text-h4 secondary--text font-weight-bold text-center text-md-left">{{ $t('start_now') }}</h1>
          <div class="d-flex">
            <img class="mr-2" src="@/assets/images/page-img/chat-two-bubbles-oval--messages-message-bubble-chat-oval-conversation.svg"/>
            <p class="text-left font-size-16 mt-4" v-html="$t('create_account')"/>
          </div>
          <div class="d-flex">
            <img class="mr-2" src="@/assets/images/page-img/edit-image-photo.svg"/>
            <p class="text-left font-size-16 mt-4" v-html="$t('complete_profile')"/>
          </div>
          <div class="d-flex">
            <img class="mr-2" src="@/assets/images/page-img/user-single-neutral-male--close-geometric-human-person-single-up-user-male.svg"/>
            <p class="text-left font-size-16 mt-4" v-html="$t('contact_clients')"/>
          </div>
          <div class="d-flex justify-content-center justify-content-md-start">
            <general-button :action="goToSignUp" :loading="false" :message="$t('wantToSignUp')" size="medium" cssClasses="ml-0 align-self-center align-self-md-start mt-5" secondary/>
          </div>
        </v-col>
    </v-row>
    <div
    :style="{
    backgroundImage: `url(${require('@/assets/images/page-img/cards_resources_vector.png')})`,
    backgroundPosition: 'center bottom'
  }"  >
    <v-row class="py-8">
          <v-col cols="12" md="6"  class="text-center">
            <p class="text-h4 secondary--text font-weight-bold  text-left ml-5">{{ $t('dedicated_resources') }}</p>
            <p class=" ml-5 text-left" v-html="$t('optimize_time')" />
          </v-col>
          <v-col cols="6" v-show="!$vuetify.breakpoint.mobile">
            <v-row>
              <v-col>
                <advantages-card :description="cards[0].text" :title="cards[0].title" cssClasses="ma-2" :imageUrl="require(`@/assets/images/page-img/${cards[0].icon}`)"/>
                <advantages-card :description="cards[1].text" :title="cards[1].title" cssClasses="ma-2" :imageUrl="require(`@/assets/images/page-img/${cards[1].icon}`)"/>
              </v-col>
              <v-col>
                <advantages-card :description="cards[2].text" :title="cards[2].title" cssClasses="ma-2" :imageUrl="require(`@/assets/images/page-img/${cards[2].icon}`)"/>
                <advantages-card :description="cards[3].text" :title="cards[3].title" cssClasses="ma-2" :imageUrl="require(`@/assets/images/page-img/${cards[3].icon}`)"/>
              </v-col>
          </v-row>
          </v-col>
          <v-col v-show="$vuetify.breakpoint.mobile" cols="12" class="text-center" style="height: 490px;">
            <hooper :centerMode="true"  class="h-100" :settings='hooperSettings' >
              <slide  v-for="(item, n) in cards" :key="n">
                <advantages-card :description="item.text" :title="item.title" cssClasses="ma-2" :imageUrl="require(`@/assets/images/page-img/${item.icon}`)"/>
              </slide>
              <hooper-pagination slot="hooper-addons"></hooper-pagination>
            </hooper>
          </v-col>
    </v-row>
    </div>

    <video-banner class="margin-bottom-36" :video-url="video.url"  :is-youtube="true" />

    <v-col cols="12" class="m-0 py-8" v-if="showTestimonials">
      <Testimonials />
    </v-col>

    <v-row class="background_contrast border-radius-medium  align-items-center pl-3 pr-3 pr-lg-0 pl-lg-0">
      <v-col cols="12" sm="6">
        <h1 class="text-h4 text-md-h3 secondary--text font-weight-bold text-center text-md-left">
          {{ $t('visibility_management') }}
        </h1>
        <p class="font-size-16 text-center text-md-left mt-2">
          {{ $t('subscribe_tools') }}
        </p>
        <div class="d-flex justify-content-center justify-content-md-start">
          <general-button :action="goToSignUp" :loading="false" :message="$t('startNow')" size="medium" cssClasses="mt-4"/>
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <div
          :style="{
            backgroundImage: `url(${require('@/assets/images/page-img/Vector_3.png')})`,
            backgroundPosition: 'center bottom'
          }"
        >
          <img :src="i18nImage('home_banner_profiles.png')" class="img-fluid"/>
        </div>
      </v-col>
    </v-row>
  </v-main>
</template>
<script>
import Testimonials from '@/components/socialvue/testimonials/Testimonials';
import { USER_TYPE_CLIENT, USER_TYPE_PROSKER } from '@/misc/constants';
import { mapActions } from 'vuex';
import userDataMixin from '@/mixins/userDataMixin';
import GeneralButton from '@/components/socialvue/buttons/GeneralButton.vue';
import TestimonialsCard from '@/components/socialvue/testimonials/TestimonialsCard.vue';
import AdvantagesCard from '@/components/socialvue/cards/AdvantagesCard.vue';
import { Hooper, Slide, Pagination as HooperPagination } from 'hooper';
import VideoBanner from '@/components/socialvue/banners/VideoBanner';
import i18ImagesMixin from '@/mixins/i18ImagesMixin';

import 'hooper/dist/hooper.css';

export default {
  name: 'HomeProskers',
  components: {
    Testimonials,
    TestimonialsCard,
    GeneralButton,
    AdvantagesCard,
    Hooper,
    Slide,
    HooperPagination,
    VideoBanner
  },
  mixins: [userDataMixin, i18ImagesMixin],
  data () {
    return {
      cards: [
        {
          icon: 'calendar-add--add-calendar-date-day-month.svg',
          title: this.$t('proskerTilesOneTitle'),
          text: this.$t('proskerTilesOneText')
        },
        {
          icon: 'dollar-coin-1--accounting-billing-payment-cash-coin-currency-money-finance.svg',
          title: this.$t('proskerTilesThreeTitle'),
          text: this.$t('proskerTilesThreeText')
        },
        {
          icon: 'chat-bubble-oval-notification--messages-message-bubble-chat-oval-notify-ping.svg',
          title: this.$t('proskerTilesTwoTitle'),
          text: this.$t('proskerTilesTwoText')
        },
        {
          icon: 'startup--shop-rocket-launch-startup.svg',
          title: this.$t('proskerTilesFourTitle'),
          text: this.$t('proskerTilesFourText')
        }
      ],
      video: {
        url: process.env.VUE_APP_LOCATION.includes('br') ? 'https://www.youtube.com/embed/0Jl5D0kFptk?si=QXPKLKXujvul98Eu' : 'https://www.youtube.com/embed/jp9rOLDpih0?si=PaKrAZwDgUi8pglD',
        title: this.$t('videoTitle'),
        ctaText: this.$t('wantToSignUp')
      },
      tiles: [
        {
          title: this.$t('proskerTilesOneTitle'),
          text: this.$t('proskerTilesOneText')
        },
        {
          title: this.$t('proskerTilesTwoTitle'),
          text: this.$t('proskerTilesTwoText')
        },
        {
          title: this.$t('proskerTilesThreeTitle'),
          text: this.$t('proskerTilesThreeText')
        },
        {
          title: this.$t('proskerTilesFourTitle'),
          text: this.$t('proskerTilesFourText')
        }
      ],
      hooperSettings: {
                    infiniteScroll: true,
                    centerMode: true,
                    autoPlay: true,
                    playSpeed: 3500,
                    breakpoints: {
                        2400: {
                            itemsToShow: 4
                        },
                        1800: {
                            itemsToShow: 4
                        },
                        1500: {
                            itemsToShow: 3
                        },
                        900: {
                            itemsToShow: 3
                        },
                       500: {
                            itemsToShow: 2
                        },
                        0: {
                            itemsToShow: 1
                        }
                    }
                }
    };
  },
  computed: {
    showTestimonials () {
      return process.env.VUE_APP_HIDE_TESTIMONIALS_CARROUSEL !== 'true';
    },
    localeClass () {
      return `locale-${this.$i18n.locale}`;
    }
  },
  created () {
    this.setImportedConstants();
    if (this.currentUser && this.currentUser.type_user === this.$options.USER_TYPE_CLIENT) {
      this.$router.push({ name: 'social.clients-page' });
    }
  },
  methods: {
    ...mapActions({
      updateSignUpUserType: 'auth/updateSignUpUserType'
    }),
    setImportedConstants () {
        this.$options.USER_TYPE_CLIENT = USER_TYPE_CLIENT;
        this.$options.USER_TYPE_PROSKER = USER_TYPE_PROSKER;
    },
    goToSubscriptions () {
      this.$router.push({ name: 'social.subscription-page' });
    },
    goToBuy () {
      this.$router.push({ name: 'social.how-works-clients' });
    },
    goToSignUp () {
      this.updateSignUpUserType(this.$options.USER_TYPE_PROSKER);
      this.$router.push({ name: 'auth1.sign-up1' });
    }
  },
  mounted () {
    this.trackUserData('load_content', {
      pageType: 'home'
    });
  }
};
</script>
<style lang="scss" scoped>
.top-container {
  margin-top: 0;
}

.locale-br {
  background-position: center right;

  .actions-sub-text,
  .hero-side-image {
    display: none !important;
  }
}

.second-banner.locale-br {
  padding-top: 56px;
}

.margin-bottom-36 {
  margin-bottom: 36px;
}

.prosker-text-section {
  margin-bottom: 80px;
}

.banner-user-menu {
  position: absolute;
  top: 0;
  right: 0;
  width: 400px;
}

.wide-banner {
  margin: 0 -56px;
  padding: 0 56px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
}

.vector-bg {
  min-height: 500px;
  position: relative;
  overflow: hidden;
  background-position: right -350px top;
}

@media screen and (max-width: 960px) {
  .locale-br {
    background: none;
  }

  .vector-bg {
    background-size: 100%;
    background-position: bottom center;
  }
}

@media screen and (max-width: 920px) {
  .banner-user-menu {
    display: none;
  }
}

</style>
